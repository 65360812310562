import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { 
  PrimarySituation,
  PrimarySituationEnum
 } from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import SelectInput from '../../../components/select-input/SelectInput';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';

type FormikValues = {
  primarySituation?: PrimarySituationEnum;
};

export default function PrimarySituationPanel() {
  const intl = useIntl();

  const userUpdate = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const settingsContext = useContext(SettingsContext);
  const { user } = useProfileCompletionContext();

  const situations: PrimarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) {
      return [];
    }
    if (user?.isCoach) {
      return settingsContext.settings.primarySituations.filter(
        (situation) =>
          situation.key === PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport ||
          situation.key === PrimarySituationEnum.PostBacStudentSupport ||
          situation.key === PrimarySituationEnum.JobSeekerSupport)
    }
    return settingsContext.settings.primarySituations;
  }, [settingsContext?.settings]);

  const formik = useFormik<FormikValues>({
    initialValues: {
      primarySituation: undefined,
    },
    onSubmit: async ({ primarySituation }) => {
      networkState.loading();
      if (!primarySituation) {
        return;
      }

      await userUpdate({ primarySituation: primarySituation });
      networkState.succeeded();
    },
  });

  const isValid = useMemo(() => {
    return !!formik.values.primarySituation;
  }, [formik.values.primarySituation]);

  return (
    <form onSubmit={formik.handleSubmit}>
          <SelectInput
            dataCy="primary-situation"
            name="primarySituation"
            values={(situations || []).map(
              ({ key, name }) => ({
                value: key,
                translation: name,
              }),
            )}
            onChange={(value) =>
              formik.setFieldValue('primarySituation', value)
            }
          />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!isValid}
      />
    </form>
  );
}
