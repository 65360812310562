import { useCallback, useContext, useMemo } from 'react';
import SidebarCategory from './sidebar-category/SidebarCategory';

import './Sidebar.scss';
import { CATEGORIZED_PANELS } from 'scenes/profile/panels';
import { UserContext } from 'contexts/user/UserContext';
import ShareButton from './share-button/ShareButton';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import EyeIcon from 'assets/component-icons/EyeIcon';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import PublicationButton from './publication-button/PublicationButton';
import { useIntl } from 'react-intl';
import { SidebarTranslations } from './sidebar.translations';
import {
  SecondarySituationEnum,
} from '../../../../@types/graphql';

// when profile published : https://www.myjobglasses.com/professionals/${professionalId}
// when profile unpublished : {APP_FRONTEND}/professionals/${professionalId}

const PUBLISHED_PROFILE_URL = 'https://www.myjobglasses.com/professionals/';
const UNPUBLISHED_PROFILE_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_APP_FRONTEND_URL}/professionals/`;

export default function Sidebar() {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  const wantsToShare = user?.wantToShare || false;

  const isInCollege = user?.secondarySituation?.key === SecondarySituationEnum.College

  const shouldShowShareButton = user?.canBecomeAmbassador

  const shouldShowPreviewButton = useMemo(() => {
    return !user?.locatedInLaReunion &&
            !isInCollege &&
            wantsToShare;
  }, [user?.locatedInLaReunion, isInCollege, wantsToShare]);

  const onPreviewClick = useCallback(() => {
    if (!user?.professionalId) return;
    if (user?.publishedAt)
      window.open(PUBLISHED_PROFILE_URL + user.professionalId, '_blank');
    else window.open(UNPUBLISHED_PROFILE_URL + user.professionalId, '_blank');
  }, [user?.professionalId, user?.publishedAt]);

  return (
    <nav className="sidebar">
      {CATEGORIZED_PANELS.map(
        ({ title, icon, ambassadorCategory, panels }, index) => {
          if (ambassadorCategory && !user?.wantToShare) return;
          return (
            <SidebarCategory
              key={index}
              {...{ title, icon, panels }}
            />
          );
        },
      )}
      {shouldShowShareButton && (
        <ShareButton wantsToShare={wantsToShare} />
      )}
      {shouldShowPreviewButton && (
          <ButtonsGroup className="sidebar__buttons">
            <SecondaryButton
              leftIcon={EyeIcon}
              label={formatMessage(SidebarTranslations.preview)}
              onClick={onPreviewClick}
            />
            <PublicationButton />
          </ButtonsGroup>
      )}
    </nav>
  );
}
