import { DiplomaEnum } from '../../../../@types/graphql';
import { IntlShape } from 'react-intl';
import { PanelErrorTranslations } from 'scenes/profile-completion/panels/panels.translations';
import { date, mixed, object, string } from 'yup';

export const educationModalValidationSchema = (intl: IntlShape) =>
  object({
    diplomaType: mixed<DiplomaEnum>().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    diplomaTitle: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    schoolName: string().required(
      intl.formatMessage(PanelErrorTranslations.inputEmpty),
    ),
    startedAt: date()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .max(
        new Date(),
        intl.formatMessage(PanelErrorTranslations.startDateInFuture),
      ),
    endedAt: date()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .max(
        new Date(),
        intl.formatMessage(PanelErrorTranslations.endDateInFuture),
      )
      .test(
        'is-after-startedAt',
        intl.formatMessage(PanelErrorTranslations.endDateBeforeStartDate),
        function (value) {
          const { startedAt } = this.parent;
          return value >= startedAt;
        }
      ),
  });
