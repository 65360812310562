import CheckIcon from 'assets/component-icons/CheckIcon';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { InputValue, sortByTranslation } from '../types/BaseInputValue';
import './StaticAutocompleteMultiSelectInput.scss';

type StaticAutocompleteMultiSelectInput<T> = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  values: InputValue<T>[];
  selectedValues: T[];
  placeholder?: string;
  onChange: (value: T) => void;
  error?: string;
  sort?: boolean;
  dataCy?: string;
};

export default function StaticAutocompleteMultiSelectInput<T extends string>({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  values: paramValues,
  sort,
  onChange,
  selectedValues,
  dataCy,
}: StaticAutocompleteMultiSelectInput<T>) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [autocompleteValue, setAutocompleteValue] = useState('');

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const values: InputValue<T>[] = useMemo(
    () => (sort ? sortByTranslation(paramValues) : paramValues),
    [sort, paramValues],
  );

  const handleComponentClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  function handleSelection(value: InputValue<T>) {
    onChange(value.value);
    setAutocompleteValue('');
    inputRef.current?.focus();
  }

  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    };

    const handleKeydown = (ev: KeyboardEvent) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
          case 'Enter':
            ev.preventDefault();
            if (values.length > 0) {
              handleSelection(values[0]);
              setDropdownOpen(false);
            }
            break;
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isDropdownOpen, values]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          ref={inputRef}
          value={autocompleteValue}
          onChange={(event) => setAutocompleteValue(event.target.value)}
          placeholder={placeholder}
        />

        <label htmlFor={name}>
          <ChevronDownIcon
            className={classNames('select-input__main__icon', {
              'select-input__main__icon--turn': isDropdownOpen,
            })}
          />
        </label>
      </div>

      {isDropdownOpen && (
        <ul className="select-input__dropdown">
          {(sort ? sortByTranslation(values) : values)
            .filter((value) =>
              value.translation
                .toLowerCase()
                .includes(autocompleteValue.toLowerCase()),
            )
            .map((value) => (
              <li
                data-cy={dataCy && `${dataCy}__item`}
                key={value.value}
                className={classNames('select-input__dropdown__option', {
                  'select-input__dropdown__option--selected':
                    selectedValues.includes(value.value),
                })}
              >
                <button
                  type="button"
                  onClick={() => handleSelection(value)}
                >
                  <CheckIcon />
                  {value.translation}
                </button>
              </li>
            ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
