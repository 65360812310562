import { useContext, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { UserContext } from 'contexts/user/UserContext';
import PlusIcon from 'assets/component-icons/PlusIcon';
import './PastEducationsPanel.scss';
import useModal from 'components/modal/Modal.hook';
import EducationModal from '../components/education-modal/EducationModal';
import { PastEducationsPanelTranslations } from './panels.translations';
import EducationBlock from '../components/education-block/EducationBlock';
import { UserPartsFragment } from '../../../@types/graphql';

export default function PastEducationsPanel() {
  const intl = useIntl();

  const [openModal, closeModal] = useModal();

  const { user, updateUser } = useContext(UserContext);

  function onAddEducationClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    openModal({
      title: intl.formatMessage(PastEducationsPanelTranslations.addModalTitle),
      content: user?.id && (
        <EducationModal
          userId={user.id}
          onEducationCreation={(education) => {
            updateUser?.({
              ...user,
              educations: [...user.educations, education],
            });
            closeModal();
          }}
        />
      ),
    });
  }

  function onModificationModalRequest(
    education: UserPartsFragment['educations'][0],
  ) {
    openModal({
      title: intl.formatMessage(PastEducationsPanelTranslations.editModalTitle),
      content: user?.id && (
        <EducationModal
          userId={user.id}
          education={education}
          onEducationUpdate={(education) => {
            // TODO resort educations in here
            updateUser?.({
              ...user,
              educations: [
                ...user.educations.filter(({ id }) => id !== education.id),
                education,
              ],
            });
            closeModal();
          }}
          onEducationDeletion={(educationId) => {
            updateUser?.({
              ...user,
              educations: user.educations.filter(
                ({ id }) => id !== educationId,
              ),
            });
            closeModal();
          }}
        />
      ),
    });
  }

  return (
    <form>
      <ul className="past-educations-panel__diplomas">
        <li>
          <button
            className="past-educations-panel__diplomas__add-diploma"
            onClick={onAddEducationClick}
          >
            <PlusIcon />
            <p>{intl.formatMessage(PastEducationsPanelTranslations.openModalButton)}</p>
          </button>
        </li>

        {user?.educations.map((education) => (
          <EducationBlock
            key={education.id}
            education={education}
            onModificationModalRequest={onModificationModalRequest}
          />
        ))}
      </ul>
    </form>
  );
}
