import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ShareButtonTranslations } from './ShareButton.translations';
import HeartHandIcon from 'assets/component-icons/HeartHandIcon';
import ToggleButton from 'components/toggle-button/ToggleButton';
import './ShareButton.scss';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';

type ShareButtonProps = {
  wantsToShare?: boolean;
};

export default function ShareButton({
  wantsToShare,
}: ShareButtonProps) {
  const { formatMessage } = useIntl();

  const updateUser = useUserContextUpdate();

  const [ toggleShare, setToggleShare ] = useState(wantsToShare);

  function handleClick(toggled:boolean) {
    setToggleShare(toggled);
    updateUser({ wantToShare: toggled });
  }

  return (
    <div
      className="share-action"
    >
      <div className="share-action__title-group">
        <HeartHandIcon />
        <p className="share-action__title"><b>{formatMessage(ShareButtonTranslations.title)}</b></p>
      </div>
      <p
        className="share-action__text"
        dangerouslySetInnerHTML={{
          __html: formatMessage(ShareButtonTranslations.content),
        }}
      />
      <div className="share-action__toggle">
        <p>{formatMessage(ShareButtonTranslations.toggleText)}</p>
        <div className="share-action__toggle__button">
          <ToggleButton
            isToggled={toggleShare}
            onChange={handleClick}
          />
        </div>
      </div>
    </div>
  );
}
