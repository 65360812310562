import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import { PoleEmploiTranslations } from './PoleEmploiButton.translations';
import IconFtConnectIsWhite from './icon-ft-connect-is-white.svg';

import styles from './PoleEmploiButton.module.scss';

type PoleEmploiButtonProps = {
  onClick: () => void;
};

export default function PoleEmploiButton({ onClick }: PoleEmploiButtonProps) {
  const intl = useIntl();

  return (
    <button
      type="button"
      className={styles.buttonPeConnect}
      data-toggle="tooltip"
      data-placement="bottom"
      title={intl.formatMessage(PoleEmploiTranslations.tooltip)}
      onClick={onClick}
    >
      <img
        src={IconFtConnectIsWhite}
        alt="Ic&ocirc;ne France Travail"
        width="34"
        height="31"
      />
      <span className={styles.text}>
        {intl.formatMessage(AuthenticationTranslations.franceTravail)}
      </span>
    </button>
  );
}
