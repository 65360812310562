import CheckIcon from 'assets/component-icons/CheckIcon';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import { IconProps } from 'assets/component-icons/Icon';
import LoadingIcon from 'assets/component-icons/LoadingIcon';
import classNames from 'classnames';
import { ChangeEvent, ComponentType, useEffect, useRef, useState } from 'react';
import { InputValue } from '../types/BaseInputValue';
import './DynamicAutocompleteSelectInput.scss';

type DynamicAutocompleteSelectInputProps<T> = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  values: InputValue<T>[];
  placeholder?: string;
  value?: string;
  onSelection: (value: T) => void;
  onTyping: (value: string) => void;
  error?: string;
  loading?: boolean;
  dataCy?: string;
};

export default function DynamicAutocompleteSelectInput<T extends string>({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  values,
  onSelection,
  onTyping,
  value = '',
  loading,
  dataCy,
}: DynamicAutocompleteSelectInputProps<T>) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [autocompleteTextValue, setAutocompleteTextValue] = useState(value);
  useEffect(() => {
    setAutocompleteTextValue(value);
  }, [value]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleComponentClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  function handleTyping(e: ChangeEvent<HTMLInputElement>) {
    setAutocompleteTextValue(e.target.value);
    onTyping(e.target.value);
  }

  function handleSelection(value: InputValue<T>) {
    onSelection(value.value);
    setAutocompleteTextValue(value.value);
    handleComponentClick();
  }
  
  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    };

    const handleKeydown = (ev: KeyboardEvent) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
          case 'Enter':
            ev.preventDefault();
            if (values.length > 0) {
              handleSelection(values[0]);
              setDropdownOpen(false);
            }
            break;
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isDropdownOpen, values]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          ref={inputRef}
          value={autocompleteTextValue}
          onChange={handleTyping}
          placeholder={placeholder}
        />

        <label htmlFor={name}>
          {loading ? (
            <LoadingIcon className="select-input__main__icon" />
          ) : (
            <ChevronDownIcon
              className={classNames('select-input__main__icon', {
                'select-input__main__icon--turn': isDropdownOpen,
              })}
            />
          )}
        </label>
      </div>

      {isDropdownOpen && values.length > 0 && (
        <ul className="select-input__dropdown">
          {values.map((value) => (
            <li
              data-cy={dataCy && `${dataCy}__item`}
              key={value.value}
              className={classNames('select-input__dropdown__option')}
            >
              <button
                type="button"
                onClick={() => handleSelection(value)}
              >
                <CheckIcon />
                {value.translation}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
