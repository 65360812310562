import { ComponentType, ReactNode } from 'react';
import LockIcon from 'assets/component-icons/LockIcon';
import UserIcon from 'assets/component-icons/UserIcon';
import Rows1Icon from 'assets/component-icons/Rows1Icon';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import GraduationHatIcon from 'assets/component-icons/GraduationHatIcon';
import { IconProps } from 'assets/component-icons/Icon';
import GlassesIcon from 'assets/component-icons/GlassesIcon';
import CertificateIcon from 'assets/component-icons/CertificateIcon';
import HeartHandIcon from 'assets/component-icons/HeartHandIcon';
import { MessageDescriptor } from 'react-intl';
import IdentityPanel from './IdentityPanel';
import PasswordCreationPanel from './PasswordCreationPanel';
import PhonePanel from './PhonePanel';
import ProfessionalSituationPanel from './ProfessionalSituationPanel';
import {
  FieldCompletionName,
  FieldCompletionStatus,
} from '../../../@types/graphql';
import WelcomePanel from './WelcomePanel';
import ThankYouPanel from './ThankYouPanel';
import CurrentEducationPanel from './CurrentEducationPanel';
import ProgramAffiliationPanel from './ProgramAffiliationPanel';
import MeetingPreferencesPanel from './MeetingPreferencesPanel';
import {
  AmbassadorPresentationPanelTranslations,
  AspirationPresentationPanelTranslations,
  AvatarPanelTranslations,
  BirthdatePanelTranslations,
  CurrentEducationPanelTranslations,
  ExpertisePresentationPanelTranslations,
  FranceTravailSupportSituationTranslations,
  IdentityPanelTranslations,
  InterestPresentationPanelTranslations,
  LanguagesPanelTranslations,
  MaxMeetingsPanelTranslations,
  MeetingPlacePanelTranslations,
  MeetingPreferencesPanelTranslations,
  MemberPresentationPanelTranslations,
  OtherSituationPanelTranslations,
  PasswordCreationPanelTranslations,
  PastEducationPanelTranslations,
  PhonePanelTranslations,
  PrimarySituationPanelTranslations,
  ProfessionalSituationPanelTranslations,
  ProfessorSituationTranslations,
  ProgramAffiliationPanelTranslations,
  WantToSharePanelTranslations,
  WelcomePanelTranslations,
} from './panels.translations';
import LanguagesPanel from './LanguagesPanel';
import MessageSmileCircleIcon from 'assets/component-icons/MessageSmileCircleIcon';
import PrimarySituationPanel from './PrimarySituationPanel';
import WantToSharePanel from './WantToSharePanel';
import AvatarPanel from './AvatarPanel';
import MeetingPlacePanel from './MeetingPlacePanel';
import CameraPlusIcon from 'assets/component-icons/CameraPlusIcon';
import StarsIcon from 'assets/component-icons/StarsIcon';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import AspirationPresentationPanel from './AspirationPresentationPanel';
import FileHeartIcon from 'assets/component-icons/FileHeartIcon';
import ExpertisePresentationPanel from './ExpertisePresentationPanel';
import FileIcon from 'assets/component-icons/FileIcon';
import FileSearchIcon from 'assets/component-icons/FileSearchIcon';
import InterestPresentationPanel from './InterestPresentationPanel';
import MemberPresentationPanel from './MemberPresentationPanel';
import AmbassadorPresentationPanel from './AmbassadorPresentationPanel';
import BirthdatePanel from './BirthdatePanel';
import CalendarIcon from 'assets/component-icons/CalendarIcon';
import OtherSituationPanel from './OtherSituationPanel';
import MaxMeetingPanel from './MaxMeetingPanel';
import PastEducationPanel from './PastEducationPanel';
import ThankYouPanelUpdate from './ThankYouPanelUpdate';
import SchoolCoachSituationPanel from './SchoolCoachSituationPanel';
import FranceTravailSupportSituationPanel from './FranceTravailSupportSituationPanel';

type PanelOptions = {
  title?: MessageDescriptor;
  subtitle?: MessageDescriptor;
  component: ReactNode;
  icon: ComponentType<IconProps>;
  fields?: FieldCompletionName[];
  wip?: string;
  hideCompletionBar?: boolean;
};

class Panel {
  readonly path: string;
  readonly title?: MessageDescriptor;
  readonly subtitle?: MessageDescriptor;
  readonly component: ReactNode;
  readonly icon: ComponentType<IconProps>;
  readonly wip?: string;
  readonly hideCompletionBar?: boolean;

  private readonly fields?: FieldCompletionName[];

  constructor(path: string, options: PanelOptions) {
    this.path = path;
    this.title = options.title;
    this.subtitle = options.subtitle;
    this.component = options.component;
    this.icon = options.icon;
    this.fields = options.fields;
    this.wip = options.wip;
    this.hideCompletionBar = options.hideCompletionBar;
  }

  isComplete(fieldsNeeded: FieldCompletionStatus[]): boolean {
    if (!this.fields) {
      return false;
    }
    const fieldsToCheck = fieldsNeeded.filter((f) =>
      this.fields!.includes(f.name),
    );
    return fieldsToCheck.every((f) => f.complete);
  }
}

export const PANELS = [
  new Panel('/welcome', {
    title: WelcomePanelTranslations.title,
    subtitle: WelcomePanelTranslations.subtitle,
    component: <WelcomePanel />,
    icon: GlassesIcon,
    fields: [FieldCompletionName.Welcome],
    hideCompletionBar: true,
  }),
  new Panel('/welcome_update', {
    title: WelcomePanelTranslations.titleUpdate,
    subtitle: WelcomePanelTranslations.subtitleUpdate,
    component: <WelcomePanel />,
    icon: GlassesIcon,
    fields: [FieldCompletionName.WelcomeUpdate],
    hideCompletionBar: true,
  }),
  new Panel('/birthdate', {
    title: BirthdatePanelTranslations.title,
    subtitle: BirthdatePanelTranslations.subtitle,
    component: <BirthdatePanel />,
    icon: CalendarIcon,
    fields: [FieldCompletionName.Birthdate],
  }),
  new Panel('/password-creation', {
    title: PasswordCreationPanelTranslations.title,
    subtitle: PasswordCreationPanelTranslations.subtitle,
    component: <PasswordCreationPanel />,
    icon: LockIcon,
    fields: [FieldCompletionName.Password],
  }),
  new Panel('/identity', {
    title: IdentityPanelTranslations.title,
    subtitle: IdentityPanelTranslations.subtitle,
    component: <IdentityPanel />,
    icon: UserIcon,
    fields: [
      FieldCompletionName.Gender,
      FieldCompletionName.FirstName,
      FieldCompletionName.LastName,
    ],
  }),
  new Panel('/primary-situation', {
    title: PrimarySituationPanelTranslations.title,
    subtitle: PrimarySituationPanelTranslations.subtitle,
    component: <PrimarySituationPanel />,
    icon: Rows1Icon,
    fields: [FieldCompletionName.PrimarySituation],
  }),
  new Panel('/other-situation', {
    title: OtherSituationPanelTranslations.title,
    subtitle: OtherSituationPanelTranslations.subtitle,
    component: <OtherSituationPanel />,
    icon: BriefcaseIcon,
    fields: [
      FieldCompletionName.PreviousJobTitle,
      FieldCompletionName.PreviousCompanyName,
      FieldCompletionName.PreviousPositionYearsOfExperiences,
      FieldCompletionName.NeverWorked,
    ],
  }),
  new Panel('/ft-support-situation', {
    title: FranceTravailSupportSituationTranslations.title,
    subtitle: FranceTravailSupportSituationTranslations.subtitle,
    component: <FranceTravailSupportSituationPanel />,
    icon: BriefcaseIcon,
    fields: [ 
      FieldCompletionName.JobSeekerSupportOrganizationType,
      FieldCompletionName.JobSeekerSupportPrograms,
      FieldCompletionName.JobSeekerSupportProgramsAndPositionPanel,
    ],
  }),
  new Panel('/school-coach-situation', {
    title: ProfessorSituationTranslations.title,
    subtitle: ProfessorSituationTranslations.subtitle,
    component: <SchoolCoachSituationPanel />,
    icon: BriefcaseIcon,
    fields: [
      FieldCompletionName.StudentSupportCurrentSchoolAndPositionPanel,
    ],
  }),
  new Panel('/professional-situation', {
    title: ProfessionalSituationPanelTranslations.title,
    subtitle: ProfessionalSituationPanelTranslations.subtitle,
    component: <ProfessionalSituationPanel />,
    icon: BriefcaseIcon,
    fields: [
      FieldCompletionName.JobTitle,
      FieldCompletionName.CompanyName,
      FieldCompletionName.YearsOfExperience,
    ],
  }),
  new Panel('/current-education', {
    title: CurrentEducationPanelTranslations.title,
    subtitle: CurrentEducationPanelTranslations.subtitle,
    component: <CurrentEducationPanel />,
    icon: GraduationHatIcon,
    fields: [
      FieldCompletionName.SecondarySituation,
      FieldCompletionName.CurrentScholarityInfos,
      FieldCompletionName.PreparedDiplomaTitle,
    ],
  }),
  new Panel('/phone', {
    title: PhonePanelTranslations.title,
    subtitle: PhonePanelTranslations.subtitle,
    component: <PhonePanel />,
    icon: PhoneIcon,
    fields: [FieldCompletionName.Phone],
  }),
  new Panel('/meeting-place', {
    title: MeetingPlacePanelTranslations.title,
    subtitle: MeetingPlacePanelTranslations.subtitle,
    component: <MeetingPlacePanel />,
    icon: MarkerPinIcon,
    fields: [FieldCompletionName.MeetingPlace],
  }),
  new Panel('/languages', {
    title: LanguagesPanelTranslations.title,
    subtitle: LanguagesPanelTranslations.subtitle,
    component: <LanguagesPanel />,
    icon: MessageSmileCircleIcon,
    fields: [FieldCompletionName.Languages],
  }),
  new Panel('/avatar', {
    title: AvatarPanelTranslations.title,
    subtitle: AvatarPanelTranslations.subtitle,
    component: <AvatarPanel />,
    icon: CameraPlusIcon,
    fields: [FieldCompletionName.Avatar],
  }),
  new Panel('/program-affiliation', {
    title: ProgramAffiliationPanelTranslations.title,
    subtitle: ProgramAffiliationPanelTranslations.subtitle,
    component: <ProgramAffiliationPanel />,
    icon: CertificateIcon,
    fields: [FieldCompletionName.JoinProgram],
  }),
  new Panel('/member-presentation-student', {
    title: MemberPresentationPanelTranslations.title,
    subtitle: MemberPresentationPanelTranslations.subtitle,
    component: <MemberPresentationPanel />,
    icon: FileIcon,
    fields: [FieldCompletionName.MemberPresentationStudent],
  }),
  new Panel('/member-presentation-support', {
    title: MemberPresentationPanelTranslations.titleSupport,
    subtitle: MemberPresentationPanelTranslations.subtitleSupport,
    component: <MemberPresentationPanel />,
    icon: FileIcon,
    fields: [FieldCompletionName.SupportMemberPresentation],
  }),
  new Panel('/want-to-share', {
    title: WantToSharePanelTranslations.title,
    subtitle: WantToSharePanelTranslations.subtitlePro,
    component: <WantToSharePanel />,
    icon: HeartHandIcon,
    fields: [FieldCompletionName.WantToShare],
  }),
  new Panel('/want-to-share-student', {
    title: WantToSharePanelTranslations.title,
    subtitle: WantToSharePanelTranslations.subtitleStudent,
    component: <WantToSharePanel />,
    icon: HeartHandIcon,
    fields: [FieldCompletionName.WantToShareStudent],
  }),
  new Panel('/max-meeting', {
    title: MaxMeetingsPanelTranslations.title,
    subtitle: MaxMeetingsPanelTranslations.subtitle,
    component: <MaxMeetingPanel />,
    icon: HeartHandIcon,
    fields: [FieldCompletionName.MaxMeetingPerMonth],
  }),
  new Panel('/meeting-preferences', {
    title: MeetingPreferencesPanelTranslations.title,
    subtitle: MeetingPreferencesPanelTranslations.subtitle,
    component: <MeetingPreferencesPanel />,
    icon: HeartHandIcon,
    fields: [FieldCompletionName.MeetingPreferences],
  }),
  new Panel('/ambassador-presentation', {
    title: AmbassadorPresentationPanelTranslations.title,
    subtitle: AmbassadorPresentationPanelTranslations.subtitle,
    component: <AmbassadorPresentationPanel />,
    icon: FileIcon,
    fields: [FieldCompletionName.AmbassadorPresentation],
  }),
  new Panel('/interest-presentation', {
    title: InterestPresentationPanelTranslations.title,
    subtitle: InterestPresentationPanelTranslations.subtitle,
    component: <InterestPresentationPanel />,
    icon: FileHeartIcon,
    fields: [FieldCompletionName.InterestsPresentation],
  }),
  new Panel('/aspiration-presentation', {
    title: AspirationPresentationPanelTranslations.title,
    subtitle: AspirationPresentationPanelTranslations.subtitle,
    component: <AspirationPresentationPanel />,
    icon: FileSearchIcon,
    fields: [FieldCompletionName.AspirationPresentation],
  }),
  new Panel('/expertise-presentation', {
    title: ExpertisePresentationPanelTranslations.title,
    subtitle: ExpertisePresentationPanelTranslations.subtitle,
    component: <ExpertisePresentationPanel />,
    icon: FileIcon,
    fields: [FieldCompletionName.ExpertisePresentation],
  }),
  new Panel('/past-education', {
    title: PastEducationPanelTranslations.title,
    subtitle: PastEducationPanelTranslations.subtitle,
    component: <PastEducationPanel />,
    icon: GraduationHatIcon,
    fields: [FieldCompletionName.AcquiredDiploma],
  }),
  new Panel('/member-presentation', {
    title: MemberPresentationPanelTranslations.title,
    subtitle: MemberPresentationPanelTranslations.subtitle,
    component: <MemberPresentationPanel />,
    icon: FileIcon,
    fields: [FieldCompletionName.MemberPresentation, FieldCompletionName.EditionMemberPresentation],
  }),
  new Panel('/thank-you', {
    component: <ThankYouPanel />,
    icon: StarsIcon,
    hideCompletionBar: true,
    fields: [FieldCompletionName.ShowCongratulationsPanel],
  }),
  new Panel('/thank-you-update', {
    component: <ThankYouPanelUpdate />,
    icon: StarsIcon,
    hideCompletionBar: true,
    fields: [FieldCompletionName.ShowCongratulationsPanelUpdate],
  }),
];
