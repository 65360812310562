import './CurrentEducationPanel.scss';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import GraduationHatIcon from 'assets/component-icons/GraduationHatIcon';
import { CommonTranslations } from 'i18n/common.translations';
import { GradeEnumTranslations } from 'i18n/grade.translations';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables,
  GetGouvSchoolsQuery,
  GetGouvSchoolsQueryVariables,
  GradeEnum,
  SecondarySituation,
  SecondarySituationEnum,
} from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import SelectInput from '../../../components/select-input/SelectInput';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import './CurrentEducationPanel.scss';
import { CurrentEducationPanelTranslations } from './panels.translations';
import { currentEducationPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';

import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_DEPARTMENTS_QUERY, GET_GOUV_SCHOOLS } from 'gqls/Schools.gql';
import DynamicAutocompleteSelectInput from 'components/dynamic-autocomplete-select-input/DynamicAutocompleteSelectInput';
import DynamicAutocompleteCategorizedSelectInput from 'components/dynamic-autocomplete-categorized-select-input/DynamicAutocompleteCategorizedSelectInput';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import formikError from 'components/formik-helper/formik.helper';
import RadioInput from 'components/radio-input/RadioInput';
import { BinaryResponse } from 'models/BinaryResponse.enum';
import { BinaryResponseTranslations } from 'i18n/binary-response.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import TextInput from 'components/text-input/TextInput';
import useDebounce from 'hooks/use-debounce';
import GouvSchoolCreateModal from '../components/gouv-school-create-modal/GouvSchoolCreateModal';
import { GouvSchoolCreateModalTranslations } from 'scenes/profile-completion/components/gouv-school-create-modal/GouvSchoolCreateModal.translations';
import useModal from 'components/modal/Modal.hook';

type FormikValues = {
  secondarySituation?: SecondarySituationEnum;
  currentGrade?: GradeEnum;
  currentSchoolDepartment: string;
  currentSchoolName: string;
  currentSchoolCity: string;
  apprentice?: BinaryResponse;
  preparedDiplomaTitle?: string;
};

export default function CurrentEducationPanel() {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const updateUserDry = useProfileCompletionUserUpdate({ dry: true });
  const [openModal, closeModal] = useModal();

  const [availableGrades, setAvailableGrades] = useState<GradeEnum[]>(
    user?.availableGrades || []
  );
  const [departementSearchValue, setDepartementSearchValue] = useState('');
  const [schoolSearchValue, setSchoolSearchValue] = useState('');
  const [debouncedSchoolSearchValue, debouncedLoading] =
    useDebounce(schoolSearchValue);

  const networkState = useFormikNetworkState(ArrowRightIcon);

  const settingsContext = useContext(SettingsContext);

  const situations: SecondarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) return [];

    const primarySituation = settingsContext.settings.primarySituations.find(
      ({ key }) => key === user?.primarySituation?.key
    );
    if (!primarySituation || !primarySituation.secondarySituations) return [];

    return primarySituation!.secondarySituations;
  }, [
    settingsContext?.settings?.primarySituations,
    user?.primarySituation?.key,
  ]);

  const handleCreateSchool = (newSchool: { name: string; communeName: string }) => {
    formik.setFieldValue('currentSchoolName', newSchool.name);
    formik.setFieldValue('currentSchoolCity', newSchool.communeName);
    formik.setSubmitting(false);
    closeModal();
  };  

  const formik = useFormik<FormikValues>({
    initialValues: {
      secondarySituation: user?.secondarySituation?.key,
      currentSchoolDepartment: '',
      currentSchoolName: '',
      currentSchoolCity: '',
    },
    validationSchema: currentEducationPanelValidationSchema(intl),
    validateOnChange: false,
    onSubmit: async (values) => {
      networkState.loading();
      const { apprentice, ...rest } = values;
      await updateUser({
        ...rest,
        apprentice: apprentice === BinaryResponse.Yes,
      });
      networkState.succeeded();
    },
  });

  const { data: departmentsData } = useQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GET_DEPARTMENTS_QUERY, {
    variables: {
      search: departementSearchValue,
    },
  });

  const openCreateSchoolModal = () => {
    openModal({
      title: formatMessage(GouvSchoolCreateModalTranslations.title),
      content: (
        <GouvSchoolCreateModal
          onClose={closeModal}
          onSuccess={handleCreateSchool}
          establishmentType={formik.values.secondarySituation}
          departmentLabel={formik.values.currentSchoolDepartment}
        />
      ),
    });
  };

  const [loadGouvSchools, { data: schoolData, loading: schoolLoading }] =
    useLazyQuery<GetGouvSchoolsQuery, GetGouvSchoolsQueryVariables>(
      GET_GOUV_SCHOOLS
    );

  useEffect(() => {
    if (debouncedSchoolSearchValue.length > 0) {
      loadGouvSchools({
        variables: {
          department: formik.values.currentSchoolDepartment,
          establishmentType: formik.values.secondarySituation,
          term: debouncedSchoolSearchValue,
        },
      });
    }
  }, [formik.values.currentSchoolDepartment, debouncedSchoolSearchValue]);

  const onSecondarySituationChange = async (
    secondarySituation: SecondarySituationEnum
  ) => {
    const response = await updateUserDry({ secondarySituation });
    setAvailableGrades(response?.data?.updateUser?.availableGrades || []);
    formik.setFieldValue('secondarySituation', secondarySituation);
    formik.setFieldValue('currentSchoolDepartment', '');
    formik.setFieldValue('currentSchoolName', '');
    formik.setFieldValue('preparedDiplomaTitle', '');

  };

  const isForeignDepartment = !/\d/.test(formik.values.currentSchoolDepartment);
  const shouldShowSchoolEmptyStateMessageForeignDepartment = isForeignDepartment && debouncedSchoolSearchValue.length >= 1;
  const shouldShowSchoolEmptyStateMessage = shouldShowSchoolEmptyStateMessageForeignDepartment || debouncedSchoolSearchValue.length >= 5;

  return (
    <form>
      <SelectInput
        dataCy="secondary-situation"
        name="secondarySituation"
        label={intl.formatMessage(
          CurrentEducationPanelTranslations.secondarySituation
        )}
        icon={GraduationHatIcon}
        initialValue={
          user?.secondarySituation && {
            value: user.secondarySituation.key,
            translation: user.secondarySituation.name,
          }
        }
        values={situations.map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        onChange={onSecondarySituationChange}
        error={formikError(formik.touched, formik.errors, 'secondarySituation')}
      />

      {availableGrades.length > 0 && (
        <>
          <SelectInput
            dataCy="current-grade"
            key={formik.values.secondarySituation}
            name="currentGrade"
            label={formatMessage(
              CurrentEducationPanelTranslations.currentGradeLabel
            )}
            icon={GraduationHatIcon}
            values={availableGrades.map((value) => ({
              value,
              translation: intl.formatMessage(GradeEnumTranslations[value]),
            }))}
            onChange={(grade) => formik.setFieldValue('currentGrade', grade)}
            error={formik.errors.currentGrade}
          />

          {formik.values.secondarySituation !==
            SecondarySituationEnum.College &&
            formik.values.secondarySituation !==
              SecondarySituationEnum.LyceeGt && (
              <TextInput
                dataCy="prepared-diploma-title"
                name="preparedDiplomaTitle"
                label={formatMessage(
                  CurrentEducationPanelTranslations.diplomaLabel
                )}
                icon={GraduationHatIcon}
                value={formik.values.preparedDiplomaTitle}
                onChange={formik.handleChange}
                error={formikError(
                  formik.touched,
                  formik.errors,
                  'preparedDiplomaTitle'
                )}
              />
            )}

          <DynamicAutocompleteSelectInput
            dataCy="current-school-department"
            name="currentSchoolDepartment"
            label={formatMessage(
              CurrentEducationPanelTranslations.departmentLabel
            )}
            values={(departmentsData?.departments || []).map((department) => ({
              value: department,
              translation: department,
            }))}
            value={formik.values.currentSchoolDepartment}
            onSelection={(value) =>
              formik.setFieldValue('currentSchoolDepartment', value)
            }
            onTyping={setDepartementSearchValue}
            error={formikError(
              formik.touched,
              formik.errors,
              'currentSchoolDepartment'
            )}
          />
        </>
      )}

      <DynamicAutocompleteCategorizedSelectInput
        dataCy="current-school-name"
        key={formik.values.secondarySituation}
        name="currentSchoolName"
        label={formatMessage(
          CurrentEducationPanelTranslations.currentSchoolNameLabel
        )}
        placeholder={intl.formatMessage(
          CurrentEducationPanelTranslations.currentSchoolNamePlaceholder
        )}
        value={formik.values.currentSchoolName}
        values={(schoolData?.gouvSchoolSearch || []).map((value) => ({
          value: value,
          categoryKey: value.communeName!,
          translation: value.name!,
        }))}
        onSelection={(value) => {
          formik.setFieldValue('currentSchoolName', value.name);
          formik.setFieldValue('currentSchoolCity', value.communeName);
        }}
        onTyping={setSchoolSearchValue}
        loading={debouncedLoading(schoolLoading)}
        emptyStateMessage={
          shouldShowSchoolEmptyStateMessage
            ? intl.formatMessage(
                CurrentEducationPanelTranslations.emptySchoolNameMessage
              )
            : ''
        }
        onEmptyStateClick={openCreateSchoolModal}
        disabled={formik.values.currentSchoolDepartment === ''}
        error={formikError(
          formik.touched,
          formik.errors,
          'currentSchoolName'
        )}
      />

      {formik.values.secondarySituation &&
        formik.values.secondarySituation !== SecondarySituationEnum.College && (
          <div>
            <RadioInput
              dataCy="apprentice"
              name="apprentice"
              values={Object.values(BinaryResponse).map((resp) => ({
                value: resp,
                translation: intl.formatMessage(
                  BinaryResponseTranslations[resp]
                ),
              }))}
              onSelection={(value) => formik.setFieldValue('apprentice', value)}
              label={formatMessage(
                CurrentEducationPanelTranslations.labelInputApprentice
              )}
              inline={true}
              error={formik.errors.apprentice}
            />
          </div>
        )}

      <PrimaryButton
        label={formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.dirty}
      />
    </form>
  );
}
