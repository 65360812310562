import { gql } from '@apollo/client';

export const OPENID_MUTATION = gql`
  mutation OpenidConnect(
    $identityToken: String!
    $provider: OpenidConnectProvider!
  ) {
    openidConnect(
      identityToken: $identityToken
      provider: $provider
    ) {
      email
      nonce
    }
  }
`;
